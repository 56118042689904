.content-box{
    .content-title-chip{
        margin-left: -$box-padding;
        padding-left: $box-padding;
        border-left: 3px solid #40d0ad;
    }

    .content-social-actions{
        padding-left: 0;
        list-style: none;
        margin-left: -5px;
        .item{
            display: inline-block;
            padding-right: 5px;
            padding-left: 5px;
        }
    }
}