$social-color: #40d0ad;

.add-link-chip{
    color: $social-color;
    display: inline-block;
    font-size: 1.2rem;
    .add-icon-chip{
        width: 28px;
        height: 28px;
        border-radius: 28px;
        display: inline-block;
        text-align: center;
        border: 1px solid $social-color;
        line-height: 27px;
    }
}