/*.reply-box-image{
    position: relative;
    display:inline-block;
    .file_del {
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 5px;
        color: $primary-color;
        border-radius: 4px;
        padding:2px;
        background-color: #fff;
    }
}  weet niet zeker waar dit voor dient dus laat dit uitstaan tot ik dat wel weet :rf*/
.reply-box {
    width: 100%;
    display: flex;
    word-break: break-word;

    &.has-error {
        .tinymedium {
            border: 3px solid $error-color;
        }
    }
    .avatar-chip {
        margin-right: 10px;
    }

    .alert{
        padding:10px;
        margin-bottom:0px;
        width:100%;
    }

    form {
        width: 100%;
        align-self: center;
        font-size:1.3rem;
        .tinymedium {
            float: left;
            width: 100%;
            padding: 10px 15px;
            border-radius: 4px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border: 1px solid $border-color;

            p:last-child {
                margin-bottom: 0;
            }
        }

        .has-error {
            .tinymedium {
                border: 3px solid $error-color;
            }
        }
    }

    .button-wrapper {
        display: flex;
        .button {
            float: left;
            border-radius: 0px;
            border: 1px solid $primary-color;
            border-left: 1px solid $border-color;
            &:first-of-type {
                border-left: none;
            }
            &:last-of-type {
                border-left: none;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
            &.reply-file-upload-chip {
                .fa-plus {
                    font-size: 10px;
                }
            }
        }
    }
}