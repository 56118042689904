.avatar-list {
    margin-top: 10px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.avatar-list > li {
    display: inline-block;
    margin-right: 5px;
    float: left;
}

.avatar .remove .fa {
    position: absolute;
    color: #fff;
    font-size: 17px;
    transform: scale(0);
    top: 50%;
    left: 50%;
    margin-left: -7px;
    margin-top: -8px;
    transition: transform .3s ease-in;
}
.avatar {
    &:hover{
        text-decoration: none;
    }
    .remove {
        .fa {
            position: absolute;
            color: #fff;
            font-size: 17px;
            transform: scale(0);
            top: 50%;
            left: 50%;
            margin-left: -7px;
            margin-top: -8px;
            transition: transform .3s ease-in;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-color: $brand-danger;
            opacity: 0;
            transition: opacity .3s ease-in;
        }
        &:hover {
            &:before {
                opacity: 0.85;
            }
            .fa {
                transform: scale(1);
            }
        }
    }
}    