.button {
    font-weight: 400;
    outline: none;
    text-align: center;
    touch-action: manipulation;
    cursor: pointer;
    white-space: nowrap;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 4px;
    user-select: none;
    &:active {
        border-style: solid;
    }
    &.primary {
        background: $primary-color;
        color: #FFF;
        border-color: transparent;
        &.inverted {
            color: $primary-color;
            background: #FFF;
            border-color: $border-color;
        }
    }
}
