// Only margin-bottom the highest level reply-list-item
#reply-list {
    & > .reply-list-item {
        margin-bottom: 15px;
    }
    .panel{
        &:before,
        &:after{
            top: 15px;
        }
    }
}

.reply-list-item {
    display: flex;
    .reply-list-item-image {
        margin-right: 20px;
        margin-top: 10px;
    }
    .reply-list-item-reply {
        margin-bottom: 0px;
        width:100%;
        position:relative;
        .avatar {
            display: none;
            float: left;
            margin-right: 10px;
        }
        a.reply-name {
            font-weight: 600;
        }
        .panel-footer {
            min-height: 25px;
            display: flex;
            padding: 10px 20px;
            align-items: center;
            border-bottom: 1px solid $border-color;
        }
        .reply-actions {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: inline-block;
            width: 100%;
            li {
                display: inline-block;
                font-size: 12px;
                float: right;
                margin-left: 25px;
                &.reply-date {
                    margin-left: 0px;
                    float: left;
                    color: #9e9e9e;
                }
                &.show-replies {
                    &.open {
                        .fa-caret-down {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
        // Reply on a reply
        section[class^="reply-add"] {
            .reply-add-form {
                border-bottom: 1px solid $border-color;
                padding-right: 20px;
            }
            .avatar {
                margin: 20px;
                display: inline-block;
            }
        }
        // Second level reply
        .reply-list-item {
            padding: 15px 15px 0px 15px;
            background: #f1f1f1;
            &:last-of-type {
                padding-bottom: 15px;
            }
            .reply-list-item-reply {
                .avatar {
                    display: block;
                }
            }
        }
    }
}




// Extra breakpoint
@media(max-width: 1350px) {}

// Tablet
@media(max-width: 990px) {}

// Mobile
@media(max-width: 768px) {
    .reply-list-item {
        .reply-list-item-image {
            display: none;
        }
        .reply-list-item-reply {
            .avatar {
                display: block;
            }
            p {
                display: inline;
            }
        }
    }
}

// Mobile (small)
@media(max-width: 560px) {}
