.tag-list-chip{
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 0;
    padding: 0;
    & > li{
        display: inline-block;
    }
    .tag-chip{
        display: inline-block;
        padding: 7px $s5 $s3;
        color: #707376;
        border: $s1 solid $border-color;
        margin-right: $s3;
        border-radius: $s5;
        font-size: 1.2rem;

        .delete-tag{
            color: #707376;
            cursor: pointer;
            display: inline-block;
            margin-left: 6px;
            opacity: .5;

            &:hover{
                text-decoration: none;
                color: #dc4250;
                opacity: 1;
            }
        }
    }
}
