.avatar.avatar-large {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    img {
        &.add-icon-big{
            //border: 1px solid $brand-success;        
            border-radius: 100%;
        }        
    }
}
