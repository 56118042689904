.back-to-top{
	display: none;
	position: fixed;
	right: 20px;
	bottom: 20px;
	background-color: #323234;
	a{
		color: white;
		
	}
	&:hover{
		background-color: #454547;
		& > a{
			/*color: #adadad;*/
		}
	}
}