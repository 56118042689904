@import './avatar-list';
@import './avatar-small';
@import './avatar-medium';
@import './avatar-large';
@import './avatar-xs';
@import './avatar-xxs';

.avatar{
	//border: 1px solid $border-color;
	line-height: 0px;
    border-radius: 100%;
    text-align: center;
    background: #FFF;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
    & > span {
        display: block;
        width: 100%;
        height: 100%;

    }
}

