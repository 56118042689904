$s1:     1px;
$s2:     2px;
$s3:     4px;
$s4:     8px;
$s5:     16px;
$s6:     32px;
$s7:     64px;
$s8:     128px;
$s9:     256px;
$s10:    512px;
$s11:    1024px;
$s12:    2048px;
